// Router
import { Route, Navigate, Routes, useLocation } from 'react-router-dom';

// LazyLoad Routes
import Loadable from '../Components/Loadable';
const Verify = Loadable(() => import('./Verify'));
const Info = Loadable(() => import('./Info'));

function AppRoutes() {
  const { pathname } = useLocation();

  const isTermsOrClover =
    /\/terms/.test(pathname) || /\/static\/clover/.test(pathname);

  if (/\/terms/.test(pathname)) {
    window.location.replace('/terms/index.html');
  }

  if (/\/terms\/privacy/.test(pathname)) {
    window.location.replace('/terms/privacy/index.html');
  }

  return (
    <Routes>
      <Route path="/" element={<Info />} />
      <Route path="/verify" element={<Verify />} />
      {!isTermsOrClover && (
        <Route path="*" element={<Navigate to="/verify" />} />
      )}
    </Routes>
  );
}

export default AppRoutes;

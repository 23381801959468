import { BrowserRouter } from 'react-router-dom';

// Components
import ScrollToTop from 'Components/ScrollToTop';
import Routes from 'Routes';

// hooks
import { BreakpointsContext, useBreakpoints } from 'Hooks/useBreakpoints';

// styles
import 'Styles/index.css';
import 'sku-ui/components.scss';

function App() {
  const bp = useBreakpoints();

  return (
    <BreakpointsContext.Provider value={bp}>
      <BrowserRouter>
        <ScrollToTop>
          <Routes />
        </ScrollToTop>
      </BrowserRouter>
    </BreakpointsContext.Provider>
  );
}

export default App;

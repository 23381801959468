import { useMemo, createContext, useContext } from 'react';

import useMedia from 'Hooks/useMedia';

export const BreakpointsContext = createContext();

// for provider
export function useBreakpoints({ sm = 480, md = 960, lg = 1024 } = {}) {
  const greaterThanSm = useMedia(`(min-width: ${sm}px)`);
  const greaterThanMd = useMedia(`(min-width: ${md}px)`);
  const greaterThanLg = useMedia(`(min-width: ${lg}px)`);
  const lessThanSm = useMedia(`(max-width: ${sm}px)`);
  const lessThanMd = useMedia(`(max-width: ${md}px)`);
  const lessThanLg = useMedia(`(max-width: ${lg}px)`);

  return useMemo(
    () => ({
      greaterThan: {
        sm: greaterThanSm,
        md: greaterThanMd,
        lg: greaterThanLg,
      },
      lessThan: {
        sm: lessThanSm,
        md: lessThanMd,
        lg: lessThanLg,
      },
    }),
    [
      greaterThanSm,
      greaterThanMd,
      greaterThanLg,
      lessThanSm,
      lessThanMd,
      lessThanLg,
    ]
  );
}

// for consumer
export default function useBreakpointsContext() {
  return useContext(BreakpointsContext);
}

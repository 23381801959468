// Ref: https://objectpartners.com/2018/12/05/migrate-from-react-loadable-to-react-suspense/
import { Component, lazy, Suspense, useState } from 'react';

import Loader from 'sku-ui/Loader';

function ErrorLog({ error, errorInfo }) {
  const [expand, setExpand] = useState(false);
  return (
    <div className="container mt-10 mx-auto px-16">
      <h1 className="text-aqua-500 text-5xl font-bold">
        Oops... looks like we have some problems
      </h1>
      <div className="mt-10">
        <div className="whitespace-pre-wrap leading-snug">
          {error && error.toString()}
          <span
            className="text-xs text-gray-400 underline ml-3 cursor-pointer"
            onClick={() => setExpand(true)}
          >
            show tracing log
          </span>
          {expand && (
            <div className="text-xs text-gray-400 mt-5">
              {errorInfo.componentStack}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      const { errorComponent: ErrorComponent } = this.props;
      return <ErrorComponent {...this.state} />;
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default function Loadable(
  loader,
  { fallback = null, errorComponent = ErrorLog } = {}
) {
  const AsyncComponent = lazy(loader);
  return (props) => (
    <ErrorBoundary errorComponent={errorComponent}>
      <Suspense
        fallback={
          fallback || <Loader className="flex items-center justify-center" />
        }
      >
        <AsyncComponent {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}
